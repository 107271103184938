import Http from "~/request/server"
import type { HttpUseFetch } from "~/types/http"

const state = reactive<{
  slotGameList: {
    favoriteStar: number
    gameProviderId: number
    gameProviderName: string | null
    gameProviderSubtypeId: number
    gameTypeId: number
    hotStar: number
    id: string
    isFavorite: number
    isNew: number
    latestIcon: string
    maintenance: string
    name: string
    originalIcon: string
    status: number
  }[]
  favoriteGameList: SpaceGameRelated.SlotGameList.GameItem[]
  gameBannerList: {
    createdAt: number
    createdBy: string
    gameGroupId: number
    h5Img: string
    id: number
    name: string
    pcImg: string
    sort: number
    status: number
    updatedAt: number
    updatedBy: string
  }[]
  gameProviderList: {
    gameCount: number
    gamePagcorId: number | null
    gameProviderId: number
    gameTypeId: number
    icon: string
    id: number
    maintenance: string
    name: string
    sort: number
    status: number
  }[]
  pageProperties: {
    pageTitle: string
    gameTyeps: number[]
    // Game Provider Subtype Id
    ids: number[]
    defaultProviderId: number
    currentProviderId: number
    slotGameParams: {
      current: number
      size: number
    }
    gameTotal: number
    isLoadMore: boolean
  }
  isHaveGameProviderId: number[]
}>({
  slotGameList: [],
  gameBannerList: [],
  gameProviderList: [],
  favoriteGameList: [],
  pageProperties: {
    pageTitle: "",
    gameTyeps: [],
    ids: [],
    defaultProviderId: -1,
    currentProviderId: -1,
    slotGameParams: {
      current: 1,
      size: 30,
    },
    gameTotal: 0,
    isLoadMore: false,
  },
  isHaveGameProviderId: [],
})

export function useGameTypeManager() {
  const route = useRoute()
  state.pageProperties.currentProviderId = Number(route.query.providerId ?? -1)
  function initSatate() {
    state.slotGameList = []
    state.pageProperties.slotGameParams = {
      current: 1,
      size: 30,
    }
    state.pageProperties.gameTotal = 0
    state.pageProperties.isLoadMore = false
  }

  async function getGameTypesAndPageTitle() {
    let pageTitle = ""
    let gameTypes: number[] = []
    switch (route.name) {
      case "Favorites": {
        pageTitle = "Favorites"

        const favoriteGameList = await getSlotGameList({
          data: { category: 3 },
        })
        gameTypes = Array.from(
          new Set(
            favoriteGameList.map((it) => Number(it.gameTypeId)).filter(Boolean)
          )
        )
        state.isHaveGameProviderId = Array.from(
          new Set(
            favoriteGameList.map((it) => Number(it.gameProviderSubtypeId))
          )
        )
        state.favoriteGameList = favoriteGameList
        break
      }
      case "Originals":
        pageTitle = "Powerbtent Originals"
        gameTypes = [2]
        break
      case "Slots":
        pageTitle = "Slots"
        gameTypes = [2]
        break
      case "Bingo":
        pageTitle = "Bingo"
        gameTypes = [12]
        break
      case "Live":
        pageTitle = "Live"
        gameTypes = [3]
        break
      case "Fishing":
        pageTitle = "Fishing"
        gameTypes = [4]
        break
      case "Chess":
        pageTitle = "Chess"
        gameTypes = [5]
        break
      case "Sports":
        pageTitle = "Sports"
        gameTypes = [1]
        break
      case "Table":
        pageTitle = "Tables"
        gameTypes = [11]
        break
        case "Lottery":
          pageTitle = "Lottery"
          gameTypes = [8]
          break
      default:
        break
    }
    state.pageProperties.pageTitle = pageTitle
    state.pageProperties.gameTyeps = gameTypes
  }

  // 遊戲類頁的Banner
  async function getGameBannerList() {
    const { data }: HttpUseFetch.Response = await Http.post("GameBannerList")
    state.gameBannerList = data ?? []
  }

  // 遊戲廠商
  async function getGameProviderList() {
    const { data } = await Http.post("gameList", {
      gameTypes: state.pageProperties.gameTyeps ?? [],
    })
    const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : null;
    let currentProviderList = [
      {
        id: -1,
        name: lang == 'zh'? '所有供应商' : 'All Providers',
        status: 1,
      },
      ...(data || []),
    ].filter((item) => [1, 2].includes(Number(item.status)))

    if (route.name === "Favorites") {
      currentProviderList = currentProviderList.filter((item) => {
        return state.isHaveGameProviderId.includes(item.id) || item.id === -1
      })
    }
    state.gameProviderList = currentProviderList ?? []
  }

  // 遊戲廠商id陣列
  function getIds() {
    const defaultProviderId = state.pageProperties.defaultProviderId
    const currentProviderId = state.pageProperties.currentProviderId

    if (route.name === "Originals") {
      return (
        state.gameProviderList
          ?.filter(
            (item: GamePlatform) => item.name?.toLowerCase() === "powerbtent slots"
          ) // 之後其他類別也有，page頁group要增加，還要再確定id、name、code為基準
          ?.map((item: GamePlatform) => item.id) ?? []
      )
    }
    if (currentProviderId === defaultProviderId) {
      return (
        state.gameProviderList
          ?.filter((it) => it.id !== defaultProviderId)
          ?.map((it) => it.id) ?? []
      )
    }
    if (currentProviderId) {
      return [currentProviderId]
    }
    return state.gameProviderList?.map((it: GamePlatform) => it.id) ?? []
  }

  // 遊戲列表
  async function getSlotGameList(
    __params: Partial<SpaceGameRelated.SlotGameList.ParamsData> = {}
  ) {
    const params = {
      current: 1,
      size: 1000,
      ...__params,
      data: {
        ...__params.data,
      },
    }
    const res: HttpUseFetch.Response = await Http.post("slotGameList", params)
    const list = res.data?.list ?? []
    return list as SpaceGameRelated.SlotGameList.GameItem[]
  }
  async function getGameList() {
    const __ids = getIds()
    const category = route.name === "Favorites" ? 3 : 1
    const isSport = route.name === "Sports"
    const { current, size } = state.pageProperties.slotGameParams
    const params: SpaceGameRelated.SlotGameList.ParamsData = {
      current,
      size,
      data: {
        ids: __ids as number[],
        category,
      },
      sortField: ["hotStar"],
    }
    if (isSport) {
      params.groups = state.pageProperties.gameTyeps
    }

    const data = await (async () => {
      if (route.name === "Favorites") {
        const list = state.favoriteGameList.filter((it) =>
          __ids.includes(Number(it.gameProviderSubtypeId))
        )
        return {
          list,
          total: list.length || 0,
        }
      }
      const { data } = await Http.post("slotGameList", params)
      return data
    })()

    if (state.pageProperties.isLoadMore) {
      state.slotGameList = [...state.slotGameList, ...(data?.list ?? [])]
    } else {
      state.slotGameList = data?.list || []
    }

    state.pageProperties.gameTotal = +data?.total ?? data?.list.length ?? 0
    state.pageProperties.isLoadMore = false
  }

  return {
    initSatate,
    getGameTypesAndPageTitle,
    getGameBannerList,
    getGameProviderList,
    getIds,
    getSlotGameList,
    getGameList,
  }
}

export { state as gameTypeManagerState }
